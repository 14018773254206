/* @component id="branded-home-button" version="1.0.0" */
"use client";
import React from "react";
import Image from "next/image";

export default function BrandedHomeButton({
  src,
  width,
  height,
  alt,
}: {
  src: string;
  width: number;
  height: number;
  alt: string;
}) {
  const goHome = () => {
    window.location.href = "/";
  };
  return (
    <Image
      src={src}
      alt={alt}
      width={width}
      height={height}
      onClick={goHome}
      className="cursor-pointer"
    />
  );
}
